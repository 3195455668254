import React from 'react'
import CartComponent from '../components/CartComponent'
import Navbar from '../components/Navbar'

const CartPage = () => {

  
  return (
    <div>
       <Navbar/>
       <CartComponent/>
    </div>
  )
}

export default CartPage
